import { roleType } from '@/components/roleMark';
import services from '@/services';
import { Locale, Theme } from '@/type';
import storage from '@/utils/storage';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './store';

export type organizationType = {
  name?: string;
  id?: number;
  logo?: string;
  role?: roleType;
  organizationId?: number;
  regionRoot?: boolean;
};

export type UserInfoType = {
  id?: string;
  nickname?: string;
  logo?: string;
  avatar?: string;
  realname?: string;
  userType?: string;
};
export interface CommonState {
  locale: Locale;
  theme: Theme;
  primaryColor: string;
  currentOrg: organizationType | null;
  userInfo: UserInfoType | null;
  organization: organizationType[];
  rootOrg: organizationType | null;
}

/* DO NOT EDIT initialState */
const initialState: CommonState = {
  locale: Locale.zh_CN,
  theme: Theme.DEFAULT,
  primaryColor: '#1361B3',
  currentOrg: storage.get('current-org') ?? null,
  userInfo: storage.get('current-user-info') ?? null,
  organization: [],
  rootOrg: storage.get('root-org') ?? null,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setPrimaryColor: (state, action) => {
      state.theme = action.payload;
    },
    setCurrentOrg: (state, action) => {
      storage.set('current-org', action.payload);
      state.currentOrg = action.payload;
    },
    setRootOrg: (state, action) => {
      storage.set('root-org', action.payload);
      state.rootOrg = action.payload;
    },
    setUserInfo: (state, action) => {
      storage.set('current-user-info', action.payload);
      state.userInfo = action.payload;
    },
    setOrganization: (state, action) => {
      state.organization = action.payload;
    },
  },
});

export const {
  setLocale,
  setTheme,
  setPrimaryColor,
  setCurrentOrg,
  setRootOrg,
  setUserInfo,
  setOrganization,
} = commonSlice.actions;

export const getOrganization =
  (
    needSetCurrentOrg?: boolean,
    targetOrg?: organizationType | null,
  ): AppThunk =>
  async (dispatch, getState) => {
    const currentOrg = getState()?.common?.currentOrg;
    const res =
      await services.OrganizationController_queryOrganizationUserInfoByUser_ea4149(
        {
          qto: {},
        },
      );
    if (res?.code === 200 && res.data) {
      const formatData = (res?.data ?? [])?.map((item: any) => ({
        ...(item?.organization ?? {}),
        organizationId: item?.organization?.id,
        ...item,
      }));
      dispatch(setOrganization(formatData));
      const rootOrg = formatData.find((item) => item.regionRoot);
      dispatch(setRootOrg(rootOrg ? rootOrg : {}));
      if (needSetCurrentOrg) {
        const current = targetOrg ?? currentOrg;
        if (current?.organizationId) {
          const target = (formatData ?? [])?.find(
            (item) => item?.organizationId === current?.organizationId,
          );
          if (target) {
            dispatch(setCurrentOrg(target));
          } else {
            if (formatData?.length > 0) {
              dispatch(setCurrentOrg(formatData?.[0]));
            } else {
              // FIXME 组织都退完了
              dispatch(setCurrentOrg({}));
            }
          }
        } else {
          if (!currentOrg?.organizationId) {
            dispatch(setCurrentOrg(formatData?.[0] ?? {}));
          }
        }
      }
    }
  };

export const selectCommon = (state: RootState) => state.common;

export default commonSlice.reducer;
